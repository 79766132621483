.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

.qr_code_img {
	width: 40% !important;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.bgimg {
	background: url(./forest-bridge.jpg);
	/* background: #2050c8 !important; */
	height: 100vh;
	background-position: center;
	background-size: cover;
	position: relative;
	color: white;
	font-family: 'Courier New', Courier, monospace;
	font-size: 25px;
	overflow: hidden;
}

.topleft {
	position: absolute;
	top: 0;
	left: 16px;
}

.topright {
	position: absolute;
	top: 0;
	right: 16px;
}

.bottomleft {
	position: absolute;
	bottom: 0;
	left: 16px;
}

.middle {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	text-align: center;
	font-weight: bold !important;
}

.middle h1 {
	font-size: 50px !important;
}

.main_logo {
	height: 40px !important;
	margin: 10px !important;
	/* background-color: rgb(2, 174, 253) !important; */
}

/* ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

li {
  float: left;
}

li a {
  display: block;
  color: white;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
} */

.navbar {
	background: #2050c8 !important;
}

a.nav-link {
	color: white !important;
}

a.nav-link:hover {
	/* background-color: #17838e; */
	/* background-color: rgb(53, 48, 48); */
	background-color: rgba(8, 8, 8, 0.5);

	color: white;
}

.fa-list {
	color: white !important;
}
.terms_conditions {
	background-color: rgba(0, 0, 0, 0.5);
	color: #fff;
	border-radius: 15px !important;
	padding: 30px 50px 30px 50px !important;
}
.terms_conditions ul {
	list-style: number !important;
}

.text_with_background {
	/* background: gold !important;
  color: black !important; */
}
.privacy p {
	text-align: justify;
}

.terms_conditions p {
	text-align: justify;
}
.terms_conditions li {
	text-align: justify;
}

.bg_policy {
	background: url(./forest-bridge.jpg);
	background-position: center;
	background-size: cover;
	position: relative;
	color: white;
	font-family: 'Courier New', Courier, monospace;
	font-size: 25px;
	padding-bottom: 5px !important;
	min-height: 100vh !important;
}

.privacy {
	background-color: rgba(0, 0, 0, 0.5);
	color: #fff;
	border-radius: 15px !important;
	padding: 30px 50px 30px 50px !important;
}

@media only screen and (max-width: 600px) {
	.bg_policy {
		font-size: 15px;
	}
}

@media only screen and (max-width: 768px) {
	.qr_code_img {
		width: 100% !important;
	}
}
